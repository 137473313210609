import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import items from '../utils/nav-items';

const onNavItemSelected = (index: number) => {
  history.pushState({}, 'FinsWash', `/#${items[index].id}`);
  window.location.reload();
};

const NotFoundPage = () => (
  <Layout
    navItems={items}
    onNavItemSelected={onNavItemSelected}
    selected={-1}
    handleSelect={true}
  >
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
